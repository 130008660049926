@font-face {
    font-family: 'Noto Sans Japanese';
    font-style: normal;
    font-weight: 100;
    src: url(../webfonts/NotoSansJP-Thin.woff) format("woff");
}

@font-face {
    font-family: 'Noto Sans Japanese';
    font-style: normal;
    font-weight: 200;
    src: url(../webfonts/NotoSansJP-Light.woff) format("woff");
}

@font-face {
    font-family: 'Noto Sans Japanese';
    font-style: normal;
    font-weight: 300;
    src: url(../webfonts/NotoSansJP-DemiLight.woff) format("woff");
}

@font-face {
    font-family: 'Noto Sans Japanese';
    font-style: normal;
    font-weight: 400;
    src: url(../webfonts/NotoSansJP-Regular.woff) format("woff");
}

@font-face {
    font-family: 'Noto Sans Japanese';
    font-style: normal;
    font-weight: 500;
    src: url(../webfonts/NotoSansJP-Medium.woff) format("woff");
}

@font-face {
    font-family: 'Noto Sans Japanese';
    font-style: normal;
    font-weight: 700;
    src: url(../webfonts/NotoSansJP-Bold.woff) format("woff");
}

@font-face {
    font-family: 'Noto Sans Japanese';
    font-style: normal;
    font-weight: 900;
    src: url(../webfonts/NotoSansJP-Black.woff) format("woff");
}

body {
    color: #444;
    font-family: 'Noto Sans Japanese', sans-serif;
}

img {
    height: auto;
    max-width: 100%;
}

a {
    color: #ef8688;
    text-decoration: underline;
}

a:hover, a:active, a:focus {
    color: #ef8688;
    text-decoration: underline;
}

.l-footer {
    background-color: #cce19b;
}

@media (min-width: 992px) {
    .l-footer {
        margin-top: 56px;
    }
}

.l-header {
    background-image:  url(../image/header-back.png);
    background-repeat: repeat;
    background-size: auto;
    background-position: 0 0;
    overflow: hidden;
}

.navbar-brand {
    padding: 10px 15px;
}

.c-copyright {
    background-color: #b2c780;
    color: #fff;
    font-size: 10px;
    padding: .5em 0;
    text-align: center;
}

@media (min-width: 992px) {
    .c-copyright {
        font-size: 14px;
    }
}

.l-footer .c-copyright {
    margin-top: 24px;
}

@media (min-width: 992px) {
    .l-footer .c-copyright {
        margin-top: 0;
    }
}

.p-footer-nav {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;
}

@media (min-width: 992px) {
    .p-footer-nav {
        padding: 24px 0;
    }
}

.p-footer-nav__item {
    display: inline-block;
    margin: 0;
    font-size: 10px;
    line-height: 1;
    padding: 0 1em;
}

.p-footer-nav__item:not(:first-child) {
    border-left: 1px solid #6c803d;
}

@media (min-width: 992px) {
    .p-footer-nav__item {
        font-size: 16px;
    }
}

.p-footer-nav a {
    color: #6c803d;
    text-decoration: none;
}

.p-footer-nav a:hover, .p-footer-nav a:active, .p-footer-nav a:focus {
    text-decoration: underline;
}

.box {
    padding: 3rem;
}

.l-container {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

.wrap {
    flex: 1;
}

.v-center {
    display: flex;
    align-items: center;
}

a.wikiwiki_banner {
    background-image: url(../image/banner_wikiwiki_mobile.jpg);
    display: block;
    margin: 0 auto;
    width: 300px;
    height: 94px;
}

a.zawazawa_banner {
    background-image: url(../image/banner_zawazawa_mobile.png);
    display: block;
    margin: 0 auto;
    width: 300px;
    height: 94px;
}

.image-sorry {
    max-width: 40%;
    height: auto;
    padding: 2rem 0;
}

.article {
    padding: 2rem 0;
}

.article > h1 {
    font-size: 2rem;
}

.banners {
    padding-bottom: 2rem;
}

@media (min-width: 768px) and (max-width: 991px) {
    .banners {
        padding-bottom: 2rem;
    }
}

@media (min-width: 768px) {
    a.wikiwiki_banner {
        background-image: url(../image/banner_wikiwiki_pc.jpg);
        display: block;
        margin: 0 auto;
        width: 728px;
        height: 90px;
    }

    a.zawazawa_banner {
        background-image: url(../image/banner_zawazawa_pc.png);
        display: block;
        margin: 0 auto;
        width: 728px;
        height: 90px;
    }

    .article > h1 {
        font-size: 36px;
    }
}
